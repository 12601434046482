import React from 'react';
import { SvgIcon } from '@mui/material';
import { ISvgIconProps } from '@react/components/props/common/svgicon.props';

export interface ITickIconProps extends ISvgIconProps {}

const TickIcon = (props: ITickIconProps) => {
  const { color } = props;
  const svg = (
    <path fill={color} fillRule="nonzero" d="M12.632.13a.436.436 0 0 0-.619 0L5.49 6.621a.434.434 0 0 1-.62 0l-2.858-2.91a.434.434 0 0 0-.62 0L.13 4.846a.438.438 0 0 0 0 .624l2.882 3.024c.17.173.45.453.62.624l1.239 1.25c.17.17.449.17.62 0l8.38-8.367a.445.445 0 0 0 0-.624L12.633.129z" />
  );

  return (
    <SvgIcon viewBox="0 0 14 11" htmlColor={color} fontSize="inherit">
      {svg}
    </SvgIcon>
  );
};

TickIcon.defaultProps = {
  color: '#404040',
} as Partial<ITickIconProps>;

export default TickIcon;
