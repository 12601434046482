import React, { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Container, Typography } from '@mui/material';
import PageLockedIcon from '@react/components/icons/pageLockedIcon.component';
import { trackPage } from '@react/utils/analytics/atlaswrapper.util';
import { useHistory } from 'react-router-dom';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import SecondaryBaseOutlineButton from '@react/components/button/base/secondaryOutlinedButtonBase.component';
import useStyles from './styles';
import messages from './messages';

const Error401Page = () => {
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  const detailAnalyticModel = {
    'data-trackable': '401-page',
  };

  const pageTrackingData = {
    ...detailAnalyticModel,
    content: { asset_type: 'story' },
  };

  trackPage(pageTrackingData);

  return (
    <div className={classes.error401Background}>
      <Container
        className={classes.error401Box}
      >
        <div className={classes.errorCodeContainer}>
          <Typography
            variant='h1'
            className={classes.errorCodeText}
          >
            {401}
          </Typography>
        </div>
        <div
          className={classes.errorMessageContainer}
        >
          <Typography
            variant='h2'
            className={classes.errorMessageText}
          >
            {intl.formatMessage(messages.unAuthorizedAccess)}
          </Typography>
          <Typography
            variant='body1'
          >
            {intl.formatMessage(messages.notAvailableMessage)}
          </Typography>
          <div className={classes.buttonContainer}>
            <SecondaryBaseOutlineButton
              icon={<ArrowBackIcon />}
              onClick={goBack}
            >
              {intl.formatMessage(messages.backButton)}
            </SecondaryBaseOutlineButton>
          </div>
        </div>
        <div className={classes.bottomIcon}>
          <PageLockedIcon
            className={classes.fileSearchingIcon}
          />
        </div>
      </Container>
    </div>
  );
};

export default Error401Page;
