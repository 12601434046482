import React from 'react';
import { SvgIcon } from '@mui/material';
import { ISvgIconProps } from '@react/components/props/common/svgicon.props';

export interface IRefreshIconProps extends ISvgIconProps {}

const RefreshIcon = (props: IRefreshIconProps) => {
  const { color } = props;
  const svg = (
    <g fill="none" fillRule="evenodd" transform="translate(-2 -2)">
      <path d="M0 0h16v16H0z" />
      <path fill={color} id="a" d="M13.333 2.667l-1.562 1.562A5.316 5.316 0 0 0 8 2.667c-1.365 0-2.73.52-3.77 1.562A5.331 5.331 0 0 0 8 13.333c1.365 0 2.73-.52 3.77-1.562a5.314 5.314 0 0 0 1.536-3.238H12.23a4.23 4.23 0 0 1-1.212 2.484A4.238 4.238 0 0 1 8 12.267c-1.14 0-2.21-.444-3.017-1.25A4.238 4.238 0 0 1 3.733 8c0-1.14.444-2.21 1.25-3.017A4.238 4.238 0 0 1 8 3.733c1.14 0 2.21.444 3.017 1.25L9.598 6.4h3.735V2.667z" />
    </g>
  );

  return (
    <SvgIcon viewBox="0 0 14 11" htmlColor={color} fontSize="inherit">
      {svg}
    </SvgIcon>
  );
};

RefreshIcon.defaultProps = {
  color: '#404040',
} as Partial<IRefreshIconProps>;

export default RefreshIcon;
