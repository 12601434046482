import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { APPBAR, FONT } from '@constants/style';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorCodeContainer: {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      top: theme.spacing(-7.5),
      left: 0,
      width: '100%',
    },
    errorCodeText: {
      width: 'fit-content',
      fontSize: 72,
      fontWeight: FONT.WEIGHT.SEMIBOLD,
      color: theme.palette.primary.main,
      padding: `${theme.spacing(1.5)} ${theme.spacing(5)}`,
      background: theme.palette.background.default,
      zIndex: 1,
    },
    errorBox: {
      marginTop: theme.spacing(14),
      border: `1px dashed ${theme.palette.primary.main}`,
      maxWidth: theme.spacing(100),
      position: 'relative',
    },
    errorMessageContainer: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(16),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    errorMessageText: {
      fontSize: 40,
      fontWeight: FONT.WEIGHT.SEMIBOLD,
      marginBottom: '15px'
    },
    buttonContainer: {
      marginTop: theme.spacing(4),
      '& > button:last-of-type': {
        marginLeft: theme.spacing(2),
      },
    },
    bottomIcon: {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      bottom: theme.spacing(-10),
      left: 0,
      width: '100%',
      zIndex: 1,
    },
    fileSearchingIcon: {
      width: 170,
      height: 170,
    },
    error401Box:{
      border: `1px dashed ${theme.palette.primary.main}`,
      maxWidth: theme.spacing(100),
      position: 'relative',
      marginBottom: '70px',
    },
    error401Background:{
      paddingTop: theme.spacing(14),
      background: 'rgb(248, 249, 251)',
      height: `calc(100vh - ${APPBAR.HEIGHT}px)`,
    },
    appbar: {
      marginBottom: theme.spacing(14),
      borderBottom: '1px solid #e2e2e2',
    },
    snackBar: {
      top: '100px'
    },
    errorMessageLabel: {
      fontSize: 24,
      fontWeight: FONT.WEIGHT.REGULAR,
    },
  }),
);

export default useStyles;
