import React, { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Typography, Box, Button } from '@mui/material';
import { FONT } from '@constants/style';
import useStyles from './styles';
import MessageBase, { MessageType } from './messageBase.component';
import CloseIcon from '../icons/closeIcon.component';
import messages from './messages';
import RefreshIcon from '../icons/refreshIcon.component';
import ExclaimationMarkSquareIcon from '../icons/exclaimationMarkSquareIcon.component';

export interface IMessageWarningProps {
  onCancel?: () => void,
  onRetry?: () => void,
  title: ReactNode | string,
  message?: ReactNode | string,
  className?: string,
  isInline?: boolean,
  mini?: boolean,
}

function MessageWarning(props: IMessageWarningProps) {
  const {
    onCancel,
    onRetry,
    title,
    message,
    className,
    isInline,
    mini,
  } = props;

  const classes = useStyles();
  const intl = useIntl();

  const handleOnCancel = () => {
    onCancel && onCancel();
  };

  const renderLeadingIcon = (
    <Box className={classes.iconWarningRoot}>
      <ExclaimationMarkSquareIcon color="#FFFFFF" />
    </Box>
  );

  const renderTitle = (
    <span className={classes.textTitleWarning}>
      <Typography variant="body1">
        {title}
      </Typography>
    </span>
  );

  const renderMessage = (
    <span>
      <Typography variant="body1">
        {message}
      </Typography>
    </span>
  );
  
  const renderButtonRetry = () => {
    if (onRetry) {
      return (
        <Button 
          className={isInline ? classes.buttonRetryInline : classes.buttonRetry}
          onClick={(_) => {
            onRetry();
          }}
        >
          <RefreshIcon />
          {intl.formatMessage(messages.retry)}
        </Button>
      );
    }
    return ' ';
  };

  const renderButtonClose = () => {
    if (onCancel) {
      return (
        <CloseIcon color={FONT.COLOR.SECONDARY.MAIN} />
      );
    }
    return <></>;
  };


  return (
    <MessageBase
      className={`${classes.warningRoot} ${className}`}
      isInline={isInline}
      additionalComponent={renderButtonRetry()}
      leadingIcon={renderLeadingIcon}
      actionIcon={mini && renderButtonClose()}
      title={renderTitle}
      message={renderMessage}
      onAction={handleOnCancel}
      type={MessageType.info}
    />
  );
}

export default MessageWarning;