import { defineMessages } from 'react-intl';

export const scope = 'src.react.page.error';

export default defineMessages({
  backButton: {
    id: `${scope}.backButton`,
    defaultMessage: 'Back',
  },
  homepage: {
    id: `${scope}.homepage`,
    defaultMessage: 'Homepage',
  },
  pageNotFound: {
    id: `${scope}.pageNotFound`,
    defaultMessage: 'Page not found',
  },
  notFoundMessage: {
    id: `${scope}.notFoundMessage`,
    defaultMessage: 'We\'re sorry, the page you are looking for can\'t be found.',
  },
  unAuthorizedAccess: {
    id: `${scope}.unAuthorizedAccess`,
    defaultMessage: 'Unauthorized access',
  },
  notAvailableMessage: {
    id: `${scope}.notAvailableMessage`,
    defaultMessage: 'We\'re sorry, you do not have permission to access this page.',
  },
  getSupport: {
    id: `${scope}.getSupport`,
    defaultMessage: 'GET SUPPORT',
  }
});