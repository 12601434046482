import React, { ReactNode, Fragment } from 'react';
import { Typography, IconButton, Box } from '@mui/material';
import useStyles from './styles';

export enum MessageType {
  info,
  warning,
}

export interface IAnnouncementBaseProps {
  additionalComponent?: ReactNode,
  leadingIcon?: ReactNode,
  actionIcon?: ReactNode,
  onAction?: () => void,
  title: ReactNode,
  message: ReactNode,
  type?: MessageType,
  className?: string,
  isInline?: boolean,
  actionComponent?: ReactNode,
}

function AnnouncementBase(props: IAnnouncementBaseProps) {
  const {
    additionalComponent,
    leadingIcon,
    actionIcon,
    onAction,
    message,
    title,
    className,
    isInline,
    actionComponent,
  } = props;

  const classes = useStyles();

  const renderActionButton = (
    <IconButton
      className={classes.buttonActionRoot}
      disableRipple
      onClick={onAction}
      size="large"
    >
      {actionIcon}
    </IconButton>
  );

  const renderTextMessage = (
    <Typography
      variant="body1"
      component="span"
      className={classes.textMessage}
    >
      {message}
    </Typography>
  );

  const renderTextTitle = (
    <Typography
      variant="body1"
      component="span"
      className={classes.textTitle}
    >
      {title}
    </Typography>
  );

  const renderInlineContent = (
    <Box className={`${classes.inlineRoot} ${className}`}>
      {leadingIcon}
      <Box>
        <Box className={classes.inlineTitleRoot}>
          {renderTextTitle}
          {renderActionButton}
        </Box>
        {renderTextMessage}
        {additionalComponent}
      </Box>
    </Box>
  );

  const renderSingleLineContent = (
    <div className={`${classes.root} ${className}`}>
      <div>
        {leadingIcon}
        {renderTextTitle}
        {renderTextMessage}
        {additionalComponent}
        {actionComponent || renderActionButton}
      </div>
    </div>
  );

  return (
    <Fragment>
      {
        isInline ? renderInlineContent : renderSingleLineContent
      }
    </Fragment>
  );

}

export default AnnouncementBase;