import React from 'react';
import { SvgIcon } from '@mui/material';
import { ISvgIconProps } from '@react/components/props/common/svgicon.props';

export interface ICloseIconProps extends ISvgIconProps {
}

const CloseIcon = (props: ICloseIconProps) => {
  const { color, className } = props;
  const svg = (
    <g fill="none" fillRule="evenodd">
      <path d="M-2-2h16v16H-2z" />
      <path fill={color} fillRule="nonzero" d="M6 4.619L1.667.286a.977.977 0 0 0-1.38 1.381L4.618 6 .286 10.333a.977.977 0 0 0 1.381 1.38L6 7.382l4.333 4.333a.977.977 0 0 0 1.38-1.381L7.382 6l4.333-4.333a.977.977 0 0 0-1.381-1.38L6 4.618z" />
    </g>
  );

  return (
    <SvgIcon className={className} viewBox="0 0 12 12" htmlColor={color} fontSize="inherit">
      {svg}
    </SvgIcon>
  );
};

CloseIcon.defaultProps = {
  color: '#404040',
} as Partial<ICloseIconProps>;

export default CloseIcon;