import React, { ReactNode } from 'react';
import { Button } from '@mui/material';
import AtlasDataTrackable, { IAtlasDataTrackable } from '@react/components/atlas/atlas.component';
import { IButtonProps } from '../../props/common/button.props';
import { IMobileProps } from '../../props/common/mobile.props';
import { useStyles } from './style';

interface ISecondaryOutlinedButtonBaseProps extends IButtonProps, IMobileProps {
  size?: 'small' | 'medium' | 'large',
  icon?: ReactNode,
  children?: ReactNode,
  type?: 'submit' | 'reset' | 'button',
  inverted?: boolean,
  disabled?: boolean,
  endIcon?: ReactNode,
  atlasTrackingObj?: IAtlasDataTrackable,
}

const SecondaryOutlinedButtonBase = (props: ISecondaryOutlinedButtonBaseProps) => {
  const {
    className,
    onClick,
    onKeyDown,
    onMouseEnter,
    onMouseLeave,
    mobile,
    icon,
    endIcon,
    type,
    inverted,
    disabled,
    children,
    size,
    atlasTrackingObj,
    ...otherProps
  } = props;

  const color = 'secondary';
  const variant = 'outlined';

  const classes = useStyles();

  return (
    mobile ?
      <Button
        color={color}
        onClick={onClick}
        onKeyDown={onKeyDown}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        size={size}
        variant={variant}
        type={type}
        disableRipple
        disabled={disabled}
        classes={{
          root: `${classes.root} ${className} ${inverted ? classes.secondaryInvertedRoot : classes.secondaryOutlinedRoot}`,
        }}
        {...AtlasDataTrackable(atlasTrackingObj || {})}
        {...otherProps}
      >
        {icon}
      </Button> :
      <Button
        color={color}
        onClick={onClick}
        onKeyDown={onKeyDown}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        size={size}
        variant={variant}
        startIcon={icon}
        endIcon={endIcon}
        type={type}
        disableRipple
        disabled={disabled}
        classes={{
          root: `${classes.root} ${className} ${inverted ? classes.secondaryInvertedRoot : classes.secondaryOutlinedRoot}`,
        }}
        {...AtlasDataTrackable(atlasTrackingObj || {})}
        {...otherProps}
      >
        {children}
      </Button>
  );
};

SecondaryOutlinedButtonBase.defaultProps = {
  mobile: false,
  type: 'button',
  size: 'medium',
  inverted: false,
} as Partial<ISecondaryOutlinedButtonBaseProps>;

export default SecondaryOutlinedButtonBase;
