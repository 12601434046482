import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { FONT } from '@constants/style';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: '50px',
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      '& > div': {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: 'inherit',
        justifyContent: 'space-between',
        '& > p': {
          flex: 1,
          padding: `0px ${theme.spacing(2)}`,
        },
      },
      [theme.breakpoints.down('sm')]: {
        '& > div': {
          display: 'flex',
          '& > span > span > p': {
            padding: '0 1rem',
          },
        },
      },
    },
    infoRoot: {
      border: 'none',
      backgroundColor: '#fff',
      [theme.breakpoints.down('sm')]: {
        height: '100%',
      },
      '& > div': {
        [theme.breakpoints.down('sm')]: {
          alignItems: 'start',
        }
      },
      '& span': {
        color: '#404040 !important',
      },
    },
    warningRoot: {
      backgroundColor: '#f6ecef',
      border: 'none',
      '& > div': {
        [theme.breakpoints.down('sm')]: {
          alignItems: 'start',
        } 
      },
    },
    snackBarMessageRoot: {
      backgroundColor: 'white',
    },
    buttonActionRoot: {
      fontSize: '12px',
      width: '24px',
      height: '24px',
      margin: '0 20px',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '0.7rem',
        marginTop: '0.5rem',
        marginBottom: '0.5rem',
      },
    },
    buttonRetry: {
      width: '142px',
      height: '40px',
      color: 'black',
      fontSize: theme.spacing(2),
      '& > svg': {
        marginRight: theme.spacing(0.5),
      },
      marginLeft: theme.spacing(1),
      marginRight: '22px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginTop: '0.5rem',
        marginLeft: '0',
        marginRight: '0',
        '& > span': {
          marginLeft: '0.5rem',
          justifyContent: 'left',
        },
      },
    },
    buttonRetryInline: {
      width: 'inherit',
      height: '40px',
      fontSize: theme.spacing(2),
      '& > span > svg': {
        marginRight: theme.spacing(0.5),
      },
      margin: '2px 10px',
    },
    iconInfoRoot: {
      width: '48px',  
      height: '51px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.primary.dark,
      [theme.breakpoints.down('sm')]: {
        height: '68px',
      },
      '& > svg': {
        fontSize: '18px',
      },
    },
    iconWarningRoot: {
      width: '48px',
      height: 'inherit',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.error.dark,
      '& > svg': {
        fontSize: '20px',
      },
    },
    textMessage: {
      flex: 1,
      marginLeft: theme.spacing(1),
      lineHeight: '20px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      wordBreak: 'break-all',
      '& > p': {
        marginLeft: theme.spacing(0.5),
        flex: 1,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
        lineHeight: '16px',
        padding: '5px 0'
      },
    },
    textTitle: {
      marginLeft: theme.spacing(1),
      lineHeight: '20px',
      [theme.breakpoints.down('sm')]: {
        width: 0,
      },
    },
    textTitleInfo: {
      '& > p': {
        fontWeight: FONT.WEIGHT.SEMIBOLD,
        color: theme.palette.secondary.dark,
      },
    },
    textTitleWarning: {
      '& > p': {
        fontWeight: FONT.WEIGHT.SEMIBOLD,
        color: theme.palette.error.dark,
      },
    },
    inlineRoot: {
      display: 'flex',
      '& > div:last-child': {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        '& > span': {
          margin: '0px 10px',
        },
      },
    },
    inlineTitleRoot: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(0.5),
      '& > span': {
        margin: '0px 10px',
      },
      '& > button': {
        marginRight: '10px',
      },
    },
    commonSnackBar: {
      minWidth: 480,
      display: 'block',
      '& > div': {
        padding: 0,
        display: 'block',
        '& > div': {
          padding: 0,
        },
      },
    },
    bookmarkSnackBar: {
      minWidth: 480,
      width: '75%',
      display: 'block',
      [theme.breakpoints.down('sm')]: {
        minWidth: 326,
        height: 68,
      },
      '& > .MuiSnackbarContent-root': {
        padding: 0,
        display: 'flex',
        backgroundColor: '#fff',
        border: '1px solid #0a7a73',
        borderLeft: 'none',
        [theme.breakpoints.down('sm')]: {
          height: '100%',
        },
        '& > .MuiSnackbarContent-message': {
          padding: 0,
        },
        '& > .MuiSnackbarContent-action': { 
          marginRight: '16px',
          [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            bottom: 0,
            left: '42px',
            '& > button .MuiTypography-body1': {
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '16px',
            }
          },
        },
      },
    },
    bookmarkErrSnackBar: {
      '& > .MuiSnackbarContent-root': {
        backgroundColor: '#f6ecef',
        border: `1px solid ${theme.palette.error.dark}`,
        '& > .MuiSnackbarContent-message': {
          [theme.breakpoints.down('sm')]: {
            height: '100%',
          },
        }
      },
    },
  }),
);

export default useStyles;